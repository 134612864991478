var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.group)?_c('div',{staticClass:"datasource-container"},[_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("基础信息 "),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.show_sheet('edit', 'GROUPEDITOR')}}},[_vm._v("(修改)")])]),_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("ID：")]),_c('span',{style:({
              color: _vm.group.fullname ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group.id || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("名称：")]),_c('span',{style:({
              color: _vm.group.name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group.name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("路径：")]),_c('span',{style:({
              color: _vm.group.department_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group.department_name || "未填写")+" ")])])],1),_c('v-row',{staticClass:"d-flex flex-row align-center"},[_c('v-col',[_c('span',[_vm._v("原系统用户组ID：")]),_c('span',{style:({
              color: _vm.group.external_id ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group.external_id || "未知")+" ")])]),_c('v-col',[_c('span',[_vm._v("来源：")]),_c('span',{style:({
              color: _vm.group.source_name ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group.source_name || "未填写")+" ")])]),_c('v-col',[_c('span',[_vm._v("描述：")]),_c('span',{style:({
              color: _vm.group.description ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.group.description || "未填写")+" ")])])],1)],1)],1),(_vm.attributesArr.length > 0)?_c('v-card',{staticClass:"dense",attrs:{"elevation":"0"}},[_c('v-card-title',[_vm._v("扩展属性 "),_c('a',{on:{"click":function($event){$event.stopPropagation();return _vm.show_sheet('edit', 'EXTRALATTR')}}},[_vm._v("(修改)")])]),_c('v-card-text',_vm._l((_vm.attributesArr),function(items){return _c('v-row',{key:items.index},_vm._l((items),function(item){return _c('v-col',{key:item.id},[(item.display_name)?_c('span',[_vm._v(_vm._s(item.display_name)+"：")]):_vm._e(),(item.display_name)?_c('span',{style:({
              color: _vm.attributes[item.name] || item.default ? '' : '#9195a3'
            })},[_vm._v(" "+_vm._s(_vm.attributes[item.name] || item.default || "未填写")+" ")]):_vm._e()])}),1)}),1)],1):_vm._e(),_c(_vm.sheet_editor,{tag:"Component",attrs:{"show":_vm.drawer_status,"is_modify":_vm.current_edit_type === 'edit',"type":"edit","entity_info":_vm.group,"attributesFlat":_vm.attributesFlat}}),_c('ConfirmBox',{ref:"confirm"})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }