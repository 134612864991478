<template>
  <div class="tablist-container d-flex justify-space-between">
    <ul class="tablist">
      <li
        :class="[tabId === item.id ? 'tabitem active' : 'tabitem']"
        v-for="item in tabList"
        :key="item.id"
        @click="curItem(item.id)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="action-buttons">
      <slot name="action-buttons"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabList",
  props: ["tabList", "curTabId"],
  data() {
    return {
      tabId: 0
    };
  },
  created() {
    this.tabId = this.curTabId || 0;
  },
  methods: {
    curItem(id) {
      this.tabId = id;
      this.$emit("tabEvent", id);
    }
  }
};
</script>

<style lang="less" scoped>
.tablist-container {
  width: 100%;
  .tablist {
    list-style: none;
    display: flex;
    padding-left: 0;
    .tabitem {
      box-sizing: border-box;
      padding: 10px 16px 8px;
      font-size: 14px;
      text-align: center;
      &.active {
        border-bottom: 2px solid #0078d7;
        color: #0078d7;
        &:hover {
          background-color: #e4effa;
        }
      }
      &:hover {
        background-color: #fafafa;
        cursor: pointer;
      }
    }
  }
}
</style>

<style lang="less">
.content-wrapper > .tablist-container {
  margin-top: -12px;
}
</style>
