<template>
  <div>
    <Breadcrumbs :curData="curName" goPage="/groups" />
    <div class="main-wrapper">
      <TabList
        @tabEvent="tabEvent($event)"
        :tabList="tabList"
        :curTabId="curTabId"
      />
      <div class="tabitem-wrapper">
        <component
          :is="curCom"
          :group="group"
          :attributesArr="attributesArr"
          :attributesFlat="attributesFlat"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api_request } from "@/util/network";
import Breadcrumbs from "@/components/Breadcrumbs";
import TabList from "@/components/TabList";
import GroupInfo from "./component/GroupInfo";
import AccountList from "./component/AccountList";
import bus from "@/util/eventBus";

export default {
  name: "GroupView",
  data() {
    return {
      curTabId: 0,
      curCom: "GroupInfo",
      tabList: [
        {
          id: 0,
          name: "用户组信息"
        },
        {
          id: 1,
          name: "用户组成员"
        }
      ],
      group: {},
      curName: null,
      attributes: [],
      attributesArr: [],
      attributesFlat: []
    };
  },
  created() {
    this.fetchAccountDetail(this.$route.params.id);

    this.fetchGroupAttributes();

    let tabId = this.$route.query.tabId;
    if (tabId != null) {
      this.curTabId = Number(tabId);
      this.curCom = ["GroupInfo", "AccountList"][tabId];
    }

    bus.$off("refreshGroup").$on("refreshGroup", () => {
      this.fetchAccountDetail(this.$route.params.id);
      this.fetchGroupAttributes();
    });
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL); //这里有没有都无所谓，最好是有以防万一
      window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
    }
  },
  methods: {
    tabEvent(id) {
      this.curTabId = id;
      this.$router.push({
        path: `/group/view/${this.$route.params.id}`,
        query: {
          tabId: id
        }
      });
      history.pushState(null, null, document.URL);
    },

    fetchGroupAttributes() {
      this.$http
        .get(`api/attribute/group/list`)
        .delegateTo(api_request)
        .then(data => {
          let groupAttributes = data.attributes;
          let filterAttributes = groupAttributes.filter(item => {
            for (let key in this.attributes) {
              if (item.name === key) {
                return item;
              }
            }
          });

          if (filterAttributes.length > 0) {
            this.attributesFlat = filterAttributes;
            let result = [];
            for (let i = 0; i < filterAttributes.length; i += 3) {
              result.push(filterAttributes.slice(i, i + 3));
            }

            result.map(item => {
              let n = (3 - item.length) % 3;
              for (let i = 0; i < n; i++) {
                item.push("");
              }
              return item;
            });
            this.attributesArr = result;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    fetchAccountDetail(id) {
      return this.$http
        .get(`api/group/${id}/describe`)
        .delegateTo(api_request)
        .then(data => {
          this.group = data;
          this.attributes = data.attributes;
          let curName = data.name;
          if (curName) {
            this.curName = curName;
          }
        })
        .delegateTo(this.$snackbar.delegateError);
    },
    goBack() {
      this.$router.replace({ path: "/groups" });
    }
  },
  watch: {
    curTabId(v) {
      this.curCom = ["GroupInfo", "AccountList"][v];
    }
  },
  destroyed() {
    window.removeEventListener("popstate", this.goBack, false);
  },
  components: {
    Breadcrumbs,
    TabList,
    GroupInfo,
    AccountList
  }
};
</script>

<style lang="less" scoped>
.datasource-container {
  width: 100%;
  box-sizing: border-box;
  .every-item {
    display: flex;
    height: 50px;
    align-items: flex-start;
    margin-top: 20px;
    label {
      flex: 0 0 140px;
      text-align: left;
      color: #666;
    }
    .input-item {
      input {
        width: 400px;
        height: 30px;
        box-sizing: border-box;
        padding-left: 20px;
        outline: none;
        border-bottom: 1px solid #949494;
        font-size: 16px;
        &.active {
          border-bottom: none;
        }
      }

      .prompt {
        font-size: 12px;
        color: #666;
      }
    }
  }

  .submit-wrapper {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    .submitbtn {
      width: 120px;
      height: 40px;
      line-height: 40px;
    }
  }
}
</style>

<style lang="less" scoped>
.main-wrapper {
  width: 100%;
  box-sizing: border-box;
  padding: 0 27px;
  margin-bottom: 30px;
}
</style>
