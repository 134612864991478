<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show[0]">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            this.is_modify
              ? $t("account.Edit EXTRAL")
              : $t("account.Create EXTRAL")
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        扩展属性列表项
      </v-alert>

      <v-row no-gutters dense v-for="item in attributesFlat" :key="item.id">
        <v-col cols="3" class="mt-2" v-if="item.value_type === 'STRING'">
          <v-subheader
            ><span class="red--text" v-if="item.required">*</span>
            {{ item.display_name }}</v-subheader
          >
        </v-col>
        <v-col cols="9" v-if="item.value_type === 'STRING'">
          <v-text-field
            v-if="!(item.name === 'roles' || item.name == 'role')"
            rows="1"
            v-model="item.default"
            :placeholder="item.default"
            :hint="item.description"
            persistent-hint
          ></v-text-field>
          <p v-else class="mt-5">
            {{ item.default }}
          </p>
        </v-col>
      </v-row>

      <v-row
        no-gutters
        dense
        v-for="item in attributesFlat"
        :key="item.display_name"
      >
        <v-col cols="3" class="mt-2" v-if="item.value_type === 'DATE'">
          <v-subheader
            ><span class="red--text" v-if="item.required">*</span>
            {{ item.display_name }}</v-subheader
          >
        </v-col>
        <v-col cols="9" v-if="item.value_type === 'DATE'">
          <v-menu
            class="datepicker-menu"
            ref="menu"
            v-model="show_datepicker"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="item.default"
                :placeholder="item.display_name"
                :hint="item.description"
                append-icon="mdi-calendar-clock"
                readonly
                rows="1"
                persistent-hint
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="item.default"
              no-title
              scrollable
              :show-current="false"
              @input="show_datepicker = false"
              :day-format="date => date.split('-')[2]"
              locale="zh-cn"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>

      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="is_modify ? modify() : create()"
            >{{ is_modify ? "提交修改" : "创建" }}</v-btn
          >
          <v-btn
            class="ml-6"
            elevation="0"
            medium
            color="gary"
            @click="resetForm()"
            >重置</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";

import { iso8601 } from "@/util/misc";
import bus from "@/util/eventBus";

export default {
  name: "ExtralAttrEditor",
  props: ["entity_info", "is_modify", "show", "attributesFlat"],
  data() {
    let isTrueUrl = value => {
      let reg = /(https?):\/\/[-A-Za-z0-9+&@#/%?=~_|!:,.;]+[-A-Za-z0-9+&@#/%=~_|]/;
      return reg.test(value);
    };
    return {
      data: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      show_start_datepicker: false,
      account: {},
      defaults: {},
      valid: true,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"],
        isurl: [v => isTrueUrl(v) || "请输入正确的URL"]
      },
      // UI inter-communication
      show_datepicker: false
    };
  },
  methods: {
    resetForm(clear) {
      if (clear) {
        this.defaults = {};
      }
      this.account = Object.assign({}, this.defaults);
    },
    modify() {
      let payload = {};
      this.attributesFlat.forEach(item => {
        payload[item.name] = item.default;
      });

      this.$http
        .post(`api/group/${this.$route.params.id}/attribute/modify`, payload)
        .delegateTo(api_request)
        .then(() => {
          this.$set(this.show, 0, false);
          bus.$emit("refreshGroup");
          return "账户已成功修改";
        })
        .catch(({ code, message }) => {
          throw `账户修改失败：${this.$t(
            `${"api." + code}`
          )}, 额外信息: ${"api." + JSON.stringify(message)}`;
        })
        .delegateTo(this.$snackbar.delegate);
    },
    fetchData() {
      let editeFilterExtrl1 = this.attributesFlat;

      editeFilterExtrl1.map(item => {
        let attributes = this.entity_info.attributes;
        item.default = attributes[item.name]
          ? attributes[item.name]
          : item.default
          ? item.default
          : "";

        return item;
      });
    },
    setExpiryDate(offsetDays) {
      this.show_datepicker = false;
      let now = new Date();
      now.setDate(now.getDate() + offsetDays);
      this.account["expiry_time"] = iso8601(now);
    }
  },
  watch: {
    // watchShow(val) {
    //   if (this.is_modify && val) {
    //     this.fetchData();
    //   }
    // }
  },
  computed: {
    today() {
      return iso8601(new Date());
    },
    watchShow() {
      return this.show[0];
    }
  }
};
</script>

<style lang="sass">
.v-card__actions
  justify-content: flex-end
  .v-btn
    font-weight: bold
</style>
