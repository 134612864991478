<template>
  <v-navigation-drawer app temporary width="650px" right v-model="show[0]">
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="title">
          {{
            this.is_readonly
              ? `${$t("group.See Group")}：${entity_info.name}`
              : `${$t("group.Edit Group")}：${entity_info.name}`
          }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-divider></v-divider>
    <v-form class="pa-4" v-model="valid" ref="form" style="height: 75vh">
      <v-alert outlined dense type="info" class="text-left body-2 mb-8">
        不知道怎么填？点这里
      </v-alert>
      <v-row align="center">
        <v-col cols="3">
          <v-subheader><span class="red--text">*</span> 路径</v-subheader>
        </v-col>
        <v-col cols="9">
          <span style="font-size:14px;color: rgba(0, 0, 0, 0.6);">{{
            group.department_name
          }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader
            ><span class="red--text">*</span>
            {{ $t("group.label.name") }}</v-subheader
          >
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="group['name']"
            :placeholder="$t('group.placeholder.name')"
            :hint="$t('group.hint.name')"
            persistent-hint
            required
            :rules="rules.notNullRules"
            :readonly="is_readonly"
            :disabled="is_readonly"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader> {{ $t("group.label.external_id") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-text-field
            rows="1"
            v-model="group['external_id']"
            :placeholder="$t('group.placeholder.external_id')"
            :hint="$t('group.hint.external_id')"
            persistent-hint
            required
            :rules="rules.notNullRules"
            readonly="readonly"
            disabled="disabled"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row no-gutters dense>
        <v-col cols="3" class="mt-2">
          <v-subheader>{{ $t("group.label.description") }}</v-subheader>
        </v-col>
        <v-col cols="9">
          <v-textarea
            rows="1"
            v-model="group['description']"
            :placeholder="$t('group.placeholder.description')"
            :hint="$t('group.hint.description')"
            persistent-hint
            :auto-grow="true"
            :readonly="is_readonly"
            :disabled="is_readonly"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="d-flex" cols="3"></v-col>
        <v-col cols="9" class="text-left">
          <v-btn
            elevation="4"
            medium
            color="primary"
            @click="is_modify ? modify() : create()"
            >{{ is_modify ? "提交修改" : "创建" }}</v-btn
          >
          <v-btn
            class="ml-6"
            elevation="0"
            medium
            color="gary"
            @click="resetForm()"
            >重置</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </v-navigation-drawer>
</template>

<script>
import { api_request } from "@/util/network";
import bus from "@/util/eventBus";

export default {
  name: "GroupEditor",
  props: ["entity_info", "is_modify", "show", "is_readonly"],
  data() {
    return {
      group: {},
      defaults: {},
      valid: true,
      rules: {
        notNullRules: [v => !!v || "此项为必填项"]
      },
      // UI inter-communication
      show_datepicker: false
    };
  },
  methods: {
    modify() {
      if (this.$refs.form.validate()) {
        let payload = {
          name: this.group["name"],
          description: this.group["description"]
            ? this.group["description"]
            : ""
        };
        this.$http
          .post(`api/group/${this.group.id}/modify`, payload)
          .delegateTo(api_request)
          .then(() => {
            this.$set(this.show, 0, false);
            bus.$emit("refreshGroup");
            bus.$emit("refreshList");
            return "用户组已成功修改";
          })
          .catch(({ code, message }) => {
            throw `用户组修改失败：${this.$t(
              "api." + code
            )}, 额外信息: ${"api." + JSON.stringify(message)}`;
          })
          .delegateTo(this.$snackbar.delegate);
      }
    },
    fetchData() {
      if (this.entity_info) {
        this.group = this.entity_info;
        this.root_department_id = this.entity_info.department_id;
      }
    }
  },
  watch: {
    watchShow() {
      if (this.is_modify || this.is_readonly) {
        this.fetchData();
      }
    }
  },
  computed: {
    watchShow() {
      return this.show[0];
    }
  }
};
</script>
